import { StringObject } from '@intelliarts/ia-react-template';

import categories from '../../components/Categories/locales/categoriesLocale-en';
import dishes from '../../components/Dishes/locales/dishesLocale-en';
import menu from '../../components/Menu/locales/menuLocale-en';
import reports from '../../components/Reports/locales/reportsLocale-en';
import order from '../../components/Orders/locales/orderLocale-en';

const localizationEN: StringObject = {
  template: {
    src: {
      components: {
        layout: {
          header: {
            appName: 'Luncher'
          }
        },
        error: {
          default: {
            defaultErrorMessage: 'Ops, we\'ve got an error'
          }
        },
        button: {
          save: 'Save',
          cancel: 'Cancel',
          delete: 'Delete',
          retry: 'Retry',
          confirm: 'Confirm',
          close: 'Close'
        }
      }
    }
  },
  /********************************************/
  src: {
    components: {
      pages: {
        settingsPage: {
          language: {
            heading: 'Language',
            options: {
              uk: 'UA',
              en: 'EN'
            }
          }
        },
        notFoundPage: {
          message: 'Page not found..'
        },
      },
      categories,
      dishes,
      menu,
      reports,
      order,
    },
    /********************************************/
    config: {
      routesConfigs: {
        notFoundPageConfig: {
          name: 'Not Found Page'
        },
        settingsPageConfig: {
          name: 'Settings',
          label: 'Settings'
        },
        categoriesPageConfig: {
          name: 'Categories',
          label: 'Categories'
        },
        dishesPageConfig: {
          name: 'Dishes',
          label: 'Dishes'
        },
        menuPageConfig: {
          name: 'Menu',
          label: 'Menu'
        },
        reportsPageConfig: {
          name: 'Reports',
          label: 'Reports'
        },
        orderPageConfig: {
          name: 'Orders',
          label: 'Orders'
        }
      },
    },
    /********************************************/
    utils: {
      validators: {
        numberValidation: {
          validateIsLessThanMaximumPrice: {
            message: 'That`s too much, are you kidding? Should be less than 1000)'
          }
        }
      },
      weekDays: {
        '0': 'Sun',
        '1': 'Mon',
        '2': 'Tue',
        '3': 'Wed',
        '4': 'Thu',
        '5': 'Fri',
        '6': 'Sat',
      },
      weekDaysFull: {
        '1': 'Monday',
        '2': 'Tuesday',
        '3': 'Wednesday',
        '4': 'Thursday',
        '5': 'Friday',
      }
    },
    /********************************************/
    common: {
      accordion: {
        formAccordion: {
          textField: {
            helperText: 'Enter the value'
          }
        }
      },
      message: {
        nothingToShowMessage: 'Sorry... Nothing to show right now',
      },
    }
  }
};

export default localizationEN;
