import { Category } from '../../Categories/models/Category';

export type Dish = {
  id: number;
  name: string;
  officialName: string;
  price: number;
  isEveryday: boolean;
  description: string;
  category: Category;
  blockedCategories: Category[];
  isArchived: boolean;
}

export enum DishModelFields {
  ID = 'id',
  NAME = 'name',
  OFFICIAL_NAME = 'officialName',
  PRICE = 'price',
  IS_EVERYDAY = 'isEveryday',
  DESCRIPTION = 'description',
  CATEGORY = 'category',
  BLOCKED_CATEGORIES = 'blockedCategories',
  IS_ARCHIVED = 'isArchived',
}
