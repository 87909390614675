import { StringObject } from '@intelliarts/ia-react-template';

import categories from '../../components/Categories/locales/categoriesLocale-uk';
import dishes from '../../components/Dishes/locales/dishesLocale-uk';
import menu from '../../components/Menu/locales/menuLocale-uk';
import reports from '../../components/Reports/locales/reportsLocale-uk';
import order from '../../components/Orders/locales/orderLocale-uk';

const localizationUK: StringObject = {
  template: {
    src: {
      components: {
        layout: {
          header: {
            appName: 'Наминайко'
          }
        },
        error: {
          default: {
            defaultErrorMessage: 'Щось пішло не так, спробуйте ще раз'
          }
        },
        button: {
          save: 'Зберегти',
          cancel: 'Не треба',
          delete: 'Видалити',
          retry: 'Повторити',
          confirm: 'Най буде',
          close: 'Закрити'
        }
      }
    }
  },
  /********************************************/
  src: {
    components: {
      pages: {
        settingsPage: {
          language: {
            heading: 'Мова',
            options: {
              uk: 'Солов\'їна',
              en: 'Кредитуюча'
            }
          }
        },
        notFoundPage: {
          message: 'Перепрошую, ви часом не бачили цю сторінку? \n Ми її здається загубили.. \n' +
            'Якщо побачите - пищіть у #under-the-carpet'
        },
      },
      categories,
      dishes,
      menu,
      reports,
      order
    },
    /********************************************/
    config: {
      routesConfigs: {
        notFoundPageConfig: {
          name: 'Їжачооок, ти де?'
        },
        settingsPageConfig: {
          name: 'Налаштування',
          label: 'Налаштування'
        },
        categoriesPageConfig: {
          name: 'Категорії',
          label: 'Категорії'
        },
        dishesPageConfig: {
          name: 'Страви',
          label: 'Страви'
        },
        menuPageConfig: {
          name: 'Меню',
          label: 'Меню'
        },
        reportsPageConfig: {
          name: 'Звіти',
          label: 'Звіти'
        },
        orderPageConfig: {
          name: 'Замовлення',
          label: 'Замовлення'
        }
      },
    },
    /********************************************/
    utils: {
      validators: {
        numberValidation: {
          validateIsLessThanMaximumPrice: {
            message: 'Це дуже багато, Ви напевно помилились. Має бути менше 1000)'
          }
        }
      },
      weekDays: {
        '0': 'НД',
        '1': 'ПН',
        '2': 'ВТ',
        '3': 'СР',
        '4': 'ЧТ',
        '5': 'ПТ',
        '6': 'СБ',
      },
      weekDaysFull: {
        '1': 'Понеділок',
        '2': 'Вівторок',
        '3': 'Середа',
        '4': 'Четвер',
        '5': 'П\'ятниця',
      }
    },
    /********************************************/
    common: {
      accordion: {
        formAccordion: {
          textField: {
            helperText: 'Введіть значення'
          }
        }
      },
      message: {
        nothingToShowMessage: 'Вибачте... Зараз нічого показати',
      },
    }
  }
};

export default localizationUK;
