import { createContainer, createHook, createStore } from 'react-sweet-state';

type SelectedCategoryState = {
  selectedCategoryId: number
}

const initialState: SelectedCategoryState = {
  selectedCategoryId: 0
};

const SelectedCategoryContainer = createContainer({ displayName: 'selected-category-id-container' });

export const SelectedCategoryIdStore = createStore({
  initialState,
  actions: {
    setSelectedCategoryId:
      (selectedCategoryId: number) =>
        ({ setState }) => {
          setState({ selectedCategoryId: selectedCategoryId });
        }
  },
  containedBy: SelectedCategoryContainer,
});

export const useSelectedCategoryId = createHook(SelectedCategoryIdStore);