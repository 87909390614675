import React from 'react';

import { TextField } from '@intelliarts/ia-react-template';

import { DishModelFields } from '../../models/Dish';
import { useDishes } from '../../store/dishesSelectors';
import { DISHES_FORM_DIALOG_PATH } from '../../config/dishesFormDialogConfig';
import { useField } from 'react-final-form';
import { CategoryModelFields } from '../../../Categories/models/Category';

export const OfficialNameField = () => {
  const dishes = useDishes();

  const idValue = useField(CategoryModelFields.ID).input.value;

  return <TextField name={DishModelFields.OFFICIAL_NAME}
                    label={`${DISHES_FORM_DIALOG_PATH}.officialName.label`}
                    validator={(value, t) => ({
                      isValid: !!value?.toString().trim() &&
                        (idValue === '' ? !dishes.some(dish => value === dish.officialName) : true),
                      error: t(`${DISHES_FORM_DIALOG_PATH}.officialName.validateIsDisplayNameUnique.message`),
                    })}
  />;
};
