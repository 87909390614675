import { lazy } from 'react';

import authConfig, { NO_NAME_OPEN_MENU_GROUP } from '../../../config/authConfig';
import { OrderStore } from '../store/orderStore';

import { ReactPageConfig } from '@intelliarts/ia-react-template';

import { ORDER_SETTINGS_PAGE_LOCALE_PATH } from '../locales';
import { useStringDateRangeProps } from '../../../utils';
import OrderImageSvg from '../../../images/menuIcons/order-figma.svg';
import { PageIconWithTooltip } from '../../common/components/Icons/PageIconWithTooltip';

import { CategoryStore } from '../../Categories/store/categoriesStore';
import { MenuStore } from '../../Menu/store/menuStore';
import { DishesStore } from '../../Dishes/store/dishesStore';
import { MenuAccordionsStateStore } from '../../Menu/store/menuAccordionsState';
import { SelectedCategoryIdStore } from '../../Dishes/store/selectedCategoryIdStore';
import { OrderIsModifiedStore } from '../store/orderIsModifiedStore';

export const OrdersPageConfig: ReactPageConfig = {
  name: `${ORDER_SETTINGS_PAGE_LOCALE_PATH}.name`,
  path: '/order',
  allowedRoles: [ authConfig.roles.ADMIN, authConfig.roles.USER ],
  component: lazy(() => import('../components/OrderPage')),
  storeDependencies: [
    { store: MenuStore, useProps: useStringDateRangeProps },
    { store: OrderStore, useProps: useStringDateRangeProps },
    { store: MenuAccordionsStateStore },
    { store: CategoryStore },
    { store: DishesStore },
    { store: SelectedCategoryIdStore },
    { store: OrderIsModifiedStore }
  ],
  menuItemConfig: {
    icon: () => PageIconWithTooltip(`${ORDER_SETTINGS_PAGE_LOCALE_PATH}.name`, OrderImageSvg),
    label: `${ORDER_SETTINGS_PAGE_LOCALE_PATH}.label`,
    group: NO_NAME_OPEN_MENU_GROUP,
  },
};