import React from 'react';

import { TextField } from '@intelliarts/ia-react-template';

import { DishModelFields } from '../../models/Dish';
import { DISHES_FORM_DIALOG_PATH } from '../../config/dishesFormDialogConfig';
import { validatePriceAmount } from '../../../../utils/validators/number.validation';

export const PriceField = () => {
  return <TextField name={DishModelFields.PRICE}
                    type="number"
                    label={`${DISHES_FORM_DIALOG_PATH}.price.label`}
                    validator={validatePriceAmount}
  />;
};
