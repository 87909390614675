import {
  processValidators,
  validateIsInteger,
  validateIsNumberMoreThanZero,
  validateIsPresent,
  Validator
} from '@intelliarts/ia-react-template';

const LOCALE_PATH = 'src.utils.validators.numberValidation';

export const validatePriceAmount: Validator = (value, t) => {
  const validators = [
    validateIsPresentAndIsPositive,
    validateIsLessThanMaximumPrice,
  ];
  return processValidators(validators, value, t);
};

export const validateIsLessThanMaximumPrice: Validator = (number, t) => ({
  isValid: +number < 1000,
  error: t(`${LOCALE_PATH}.validateIsLessThanMaximumPrice.message`),
});

export const validateIsPresentAndIsPositive: Validator = (value, t) => {
  const validators = [
    validateIsPresent,
    validateIsNumberMoreThanZero,
  ];
  return processValidators(validators, value, t);
};

export const validateIsPresentIsIntegerAndIsPositive: Validator = (value, t) => {
  const validators = [
    validateIsPresent,
    validateIsInteger,
    validateIsNumberMoreThanZero,
  ];
  return processValidators(validators, value, t);
};