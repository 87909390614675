import { createContainer } from 'react-sweet-state';
import { createDataHook, createRestStore } from '@intelliarts/ia-react-template';
import { StringDateRangeProps } from '../../common/models/DateRangeProps';
import { DateToVendorReportMap } from '../models/DateToVendorReportMap';

export const VENDOR_REPORT_PATH = '/report/vendor';

const BASE_URL = '/api/';

export const VendorReportsContainer = createContainer({
  displayName: 'vendor-report-container'
});

export const VendorReportStore = createRestStore<DateToVendorReportMap, DateToVendorReportMap, StringDateRangeProps>({
  containedBy: VendorReportsContainer,
  actionConfig: {
    path: VENDOR_REPORT_PATH,
    axiosConfig: { baseURL: BASE_URL },
  },
  usePropsAsActionParams: true,
});

export const useVendorReport = createDataHook(VendorReportStore);

