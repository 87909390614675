export default {
  dishesFormDialog: {
    title: 'Ви створюєте нову страву!',
    editTitle: 'Ви редагуєте страву!',
    id: {
      label: 'Ідентифікатор'
    },
    price: {
      label: 'Ціна',
      helperText: 'Має бути корекний формат ціни!'
    },
    category: {
      label: 'Назва категорії',
      validateIsDisplayNameUnique:
        {
          message: 'Категорія є обов`язковою'
        }
    },
    blockedCategories: {
      label: 'Заблоковані категорії',
    },
    description: {
      label: 'Опис страви',
    },
    isEveryday: {
      label: 'Щоденна страва',
    },
    name: {
      label: 'Назва страви',
      validateIsDisplayNameUnique:
        {
          message: 'Назва страви є обов`язковою і має бути унікальною'
        }
    },
    officialName: {
      label: 'Назва для постачальника',
      validateIsDisplayNameUnique:
        {
          message: 'Назва для постачальника є обов`язковою і має бути унікальною'
        }
    },
  },
  dishesList: {
    columns: {
      name: {
        headerName: 'Назва'
      },
      officialName: {
        headerName: 'Назва для постачальника'
      },
      price: {
        headerName: 'Вартість'
      },
      description: {
        headerName: 'Опис'
      },
      category: {
        headerName: 'Категорія'
      },
      blockedCategories: {
        headerName: 'Заблоковані категорії'
      },
      isEveryday: {
        headerName: 'Щоденна страва',
      },
      isArchived: {
        headerName: 'Архівна',
      },
      editDish: {
        headerName: 'Редагувати страву',
        editLinkButton: {
          tooltipText: 'Редагувати'
        }
      },
    },
    toolbar: {
      displayArchivedDishesToggle: {
        label: 'Показати у т.ч. неактивні страви',
      }
    }
  }
};
