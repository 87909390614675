export default {
  categoryFormDialog: {
    title: 'You are creating Category!',
    editTitle: 'You are editing Category!',
    scope: {
      label: 'Available for'
    },
    id: {
      label: 'id'
    },
    position: {
      label: 'Display order',
      helperText: 'Only digits allowed!'
    },
    icon: {
      label: 'Icon',
      helperText: 'Select category Icon'
    },
    name: {
      label: 'Category name',
      validateIsDisplayNameUnique:
        {
          message: 'Category name should be present and unique'
        }
    },
  },
  categoriesList: {
    columns: {
      position: {
        headerName: 'Display order'
      },
      name: {
        headerName: 'Category name'
      },
      icon: {
        headerName: 'Icon'
      },
      isArchived: {
        headerName: 'Archived',
      },
      editCategory: {
        headerName: 'Edit category info',
        editLinkButton: {
          tooltipText: 'Edit'
        }
      },
    },
    toolbar: {
      displayArchivedCategoriesToggle: {
        label: 'Show also archived categories',
      },
    }
  }
};
