export type Category = {
  id: number;
  name: string;
  position: number;
  icon: string;
  isArchived: boolean;
}

export enum CategoryModelFields {
  ID = 'id',
  NAME = 'name',
  POSITION = 'position',
  ICON = 'icon',
  IS_ARCHIVED = 'isArchived',
}
