import React from 'react';

import { TextField } from '@intelliarts/ia-react-template';

import { CATEGORIES_BASE_LOCALE_PATH } from '../../locales';
import { CategoryModelFields } from '../../models/Category';
import { useCategories } from '../../store/categoriesSelectors';
import { useField } from 'react-final-form';

const LOCALE_PATH = `${CATEGORIES_BASE_LOCALE_PATH}.categoryFormDialog.name`;

export const NameField = () => {
  const categories = useCategories();

  const idValue = useField(CategoryModelFields.ID).input.value;

  return <TextField name={CategoryModelFields.NAME}
                    label={`${LOCALE_PATH}.label`}
                    validator={(value, t) => ({
                      isValid: !!value?.toString().trim() &&
                        (idValue === '' ? !categories.some(category => value === category.name) : true),
                      error: t(`${LOCALE_PATH}.validateIsDisplayNameUnique.message`),
                    })}
  />;
};
