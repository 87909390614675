import { createActionsHook, createStore } from 'react-sweet-state';
import { DishView } from '../models/DishView';

type SelectedRow = {
  selectedRow: DishView | null
}

const initialState: SelectedRow = {
  selectedRow: null
};

const SelectedRowStore = createStore({
  initialState: initialState,
  actions: {
    setSelectedRow: (row: DishView | null) => ({ setState }) => {
      setState({ selectedRow: row });
    },
    getSelectedRow: () => ({ getState }): DishView | null => {
      return getState().selectedRow;
    }
  },
});

export const useSelectedRowActions = createActionsHook(SelectedRowStore);

export default SelectedRowStore;