import { createContainer } from 'react-sweet-state';

import { createDataHook, createLoadingHook, createRestStore } from '@intelliarts/ia-react-template';

export const USERS_PATH = '/users/names';

const BASE_URL = '/api/';

const getUsers = (users: string[]) => Array.from(new Set(users));

export const UserNamesContainer = createContainer({
  displayName: 'user-names-container'
});

export const UserNamesStore = createRestStore<string>({
  containedBy: UserNamesContainer,
  actionConfig: {
    path: USERS_PATH,
    axiosConfig: { baseURL: BASE_URL },
  },
});

export const useUsers = createDataHook(UserNamesStore, getUsers);

export const useUserNamesAreLoading = createLoadingHook(UserNamesStore);
