export default {
  orderWeekPanelMessages: {
    noWeekOrderAvailable: '',
    noDailyOrderAvailable: '',
  },
  orderSidebarLabels: {
    weekPrice: 'Сума за дні: ₴',
    orderButton: 'Замовити',
    reOrderButton: 'Перезамовити',
    ordered: 'Замовлено',
    openOrderPanel: 'Відкрити панель замовлень',
    closeOrderPanel: 'Закрити панель замовлень',
    holiday: 'Вихідний',
    nothingSelected: 'Нічого не обрано',
  },
  menuWeekPanelMessages: {
    orderHasDraftMenu: 'Дане меню досі не ОПУБЛІКОВАНЕ. Неможливо замовити ЧОРНОВЕ меню.'
  },
};