import React from 'react';

import { TextField } from '@intelliarts/ia-react-template';

import { DishModelFields } from '../../models/Dish';
import { DISHES_FORM_DIALOG_PATH } from '../../config/dishesFormDialogConfig';

export const DescriptionField = () => {
  return <TextField name={DishModelFields.DESCRIPTION}
                    required={false}
                    multiline={true}
                    rows={3}
                    label={`${DISHES_FORM_DIALOG_PATH}.description.label`}
  />;
};
