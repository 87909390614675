import { createContainer, createHook, createStore } from 'react-sweet-state';

type MenuAccordionsState = {
  state: MenuAccordionsDateState[] | null
}

type MenuAccordionsDateState = {
  date: string,
  hasCollapsed: boolean,
  items: number[]
}

const initialState: MenuAccordionsState = { state: null };

export const MenuAccordionsContainer = createContainer({ displayName: 'accordions-container' });

export const MenuAccordionsStateStore = createStore({
  initialState,

  actions: {
    setMenuAccordionsState:
      (newTabState: MenuAccordionsDateState) =>
        ({ setState, getState }) => {
          let newDateState: MenuAccordionsDateState[] = [];

          const currentState = getState().state;
          const filterPageDays = currentState?.filter(tab => tab.date !== newTabState.date);

          if (filterPageDays) {
            newDateState.push(...filterPageDays);
          }
          newDateState.push(newTabState);

          setState({ state: newDateState });
        }
  },
  containedBy: MenuAccordionsContainer,

});

export const useMenuAccordionsState = createHook(MenuAccordionsStateStore);