import { FormConfig } from '@intelliarts/ia-react-template';

import { CATEGORIES_BASE_LOCALE_PATH } from '../locales';
import { CategoryView } from '../models/CategoryView';

import { PositionField } from '../components/CategoryFormDialog/PositionField';
import { NameField } from '../components/CategoryFormDialog/NameField';
import { CategoryIconField } from '../components/CategoryFormDialog/CategoryIconField';

const LOCALE_PATH = `${CATEGORIES_BASE_LOCALE_PATH}.categoryFormDialog`;

export const categoryFormDialogConfig: FormConfig<CategoryView> = {
  titles: {
    post: `${LOCALE_PATH}.title`,
    put: `${LOCALE_PATH}.editTitle`,
  },
  formFields: [
    NameField,
    PositionField,
    CategoryIconField
  ]
};
