import { AuthConfig } from '@intelliarts/ia-react-template';

export const NO_NAME_USER_MENU_GROUP = '';
export const NO_NAME_ADMIN_MENU_GROUP = ' ';
export const NO_NAME_OPEN_MENU_GROUP = '     ';
export const NO_NAME_REPORTS_GROUP = '   ';

export const USER_ROLE = 'ROLE_EATER';
export const ADMIN_ROLE = 'ROLE_FOODLORD';

const authConfig: AuthConfig = {
  profileUrl: 'users/me',
  roles: {
    USER: USER_ROLE,
    ADMIN: ADMIN_ROLE,
    ALL: 'all',
  }
};

export default authConfig;
