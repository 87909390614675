export default {
  dishesFormDialog: {
    title: 'You are creating new dish!',
    editTitle: 'You are editing dish!',
    id: {
      label: 'id'
    },
    price: {
      label: 'Price',
      helperText: 'Should be correct price format!'
    },
    category: {
      label: 'Category name',
      validateIsDisplayNameUnique:
        {
          message: 'Category must be selected'
        }
    },
    blockedCategories: {
      label: 'Blocked categories',
    },
    description: {
      label: 'Dish description',
    },
    isEveryday: {
      label: 'Everyday dish',
    },
    name: {
      label: 'Dish name',
      validateIsDisplayNameUnique:
        {
          message: 'Dish name should be present and unique'
        }
    },
    officialName: {
      label: 'Name for vendor',
      validateIsDisplayNameUnique:
        {
          message: 'Name for vendor should be present and unique'
        }
    },
  },
  dishesList: {
    columns: {
      name: {
        headerName: 'Dish name'
      },
      officialName: {
        headerName: 'Name for vendor'
      },
      price: {
        headerName: 'Price'
      },
      description: {
        headerName: 'Description'
      },
      category: {
        headerName: 'Category'
      },
      blockedCategories: {
        headerName: 'Blocked categories'
      },
      isEveryday: {
        headerName: 'Everyday dish',
      },
      isArchived: {
        headerName: 'Archived',
      },
      editDish: {
        headerName: 'Edit dish info',
        editLinkButton: {
          tooltipText: 'Edit'
        }
      },
    },
    toolbar: {
      displayArchivedDishesToggle: {
        label: 'Show also archived dishes',
      }
    }
  }
};
