import { createContainer, createHook, createStore } from 'react-sweet-state';

type filteringUsersState = {
  userNamesToFilterReportsBy: string[]
}

const initialState: filteringUsersState = {
  userNamesToFilterReportsBy: []
};

const UserNamesToFilterReportsByContainer = createContainer({ displayName: 'user-names-to-filter-reports-by-container' });

export const UsersToFilterReportsByStore = createStore({
  initialState,
  actions: {
    setUsersToFilter:
      (users: string[]) =>
        ({ setState }) => {
          setState({ userNamesToFilterReportsBy: users });
        }
  },
  containedBy: UserNamesToFilterReportsByContainer,
});

export const useUsersToFilterReportsBy = createHook(UsersToFilterReportsByStore);