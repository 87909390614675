import { FormConfig } from '@intelliarts/ia-react-template';

import { DISHES_BASE_LOCALE_PATH } from '../locales';
import { DishView } from '../models/DishView';

import { NameField } from '../components/DishesFormDialog/NameField';
import { OfficialNameField } from '../components/DishesFormDialog/OfficialNameField';
import { DishesCategoryField } from '../components/DishesFormDialog/DishesCategoryField';
import { PriceField } from '../components/DishesFormDialog/PriceField';
import { DescriptionField } from '../components/DishesFormDialog/DescriptionField';
import { BlockedCategoryField } from '../components/DishesFormDialog/BlockedCategoryField';
import { EverydayField } from '../components/DishesFormDialog/EverydayField';

export const DISHES_FORM_DIALOG_PATH = `${DISHES_BASE_LOCALE_PATH}.dishesFormDialog`;

export const dishesFormDialogConfig: FormConfig<DishView> = {
  titles: {
    post: `${DISHES_FORM_DIALOG_PATH}.title`,
    put: `${DISHES_FORM_DIALOG_PATH}.editTitle`,
  },
  formFields: [
    NameField,
    OfficialNameField,
    PriceField,
    DishesCategoryField,
    BlockedCategoryField,
    DescriptionField,
    EverydayField,
  ]
};
