import { DaoModel } from '@intelliarts/ia-react-template';

import { DishesStore } from '../store/dishesStore';
import { useSortedDishesViews } from '../store/dishesSelectors';
import { DishView } from '../models/DishView';

export class DishesDao extends DaoModel<DishView> {
  constructor() {
    super({
      modelName: 'food',
      Store: DishesStore,
    });
  }

  findByCode(code: string): DishView | null {
    const dishesViews = useSortedDishesViews();
    return dishesViews.find(cw => cw.id === +code) || null;
  }

  getAll(): DishView[] {
    return useSortedDishesViews();
  }
}

export const dishesDao = new DishesDao();
