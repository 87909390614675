import { PageConfig } from '@intelliarts/ia-react-template';

import {
  CategoriesPageConfig,
  DishesPageConfig,
  MenuPageConfig,
  NotFoundPageConfig,
  OrdersPageConfig,
  ReportsPageConfig,
  SettingsPageConfig,
} from './routesConfigs';

const routesConfig: PageConfig[] = [
  NotFoundPageConfig,
  CategoriesPageConfig,
  DishesPageConfig,
  MenuPageConfig,
  OrdersPageConfig,
  ReportsPageConfig,
  SettingsPageConfig,
];

export default routesConfig;
