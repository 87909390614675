import { lazy } from 'react';

import { RestPageConfig } from '@intelliarts/ia-react-template';

import { DISHES_SETTINGS_PAGE_LOCALE_PATH } from '../locales';
import authConfig, { NO_NAME_ADMIN_MENU_GROUP } from '../../../config/authConfig';
import { DISHES_PATH, DishesStore } from '../store/dishesStore';
import { DishView } from '../models/DishView';
import { dishesDao } from './DishesDao';
import { dishesFormDialogConfig } from './dishesFormDialogConfig';
import { CategoryStore } from '../../Categories/store/categoriesStore';
import DishesImageSvg from '../../../images/menuIcons/dishes-figma.svg';
import { SelectedCategoryIdStore } from '../store/selectedCategoryIdStore';
import { PageIconWithTooltip } from '../../common/components/Icons/PageIconWithTooltip';

export const DishesPageConfig: RestPageConfig<DishView> = {
  name: `${DISHES_SETTINGS_PAGE_LOCALE_PATH}.name`,
  path: DISHES_PATH,
  allowedRoles: [ authConfig.roles.ADMIN ],
  dao: dishesDao,
  formConfig: dishesFormDialogConfig,
  List: lazy(() => import('../components/DishesList')),
  Details: lazy(() => import('../../../components/pages/NotFoundPage')),
  storeDependencies: [
    { store: DishesStore },
    { store: CategoryStore },
    { store: SelectedCategoryIdStore }
  ],
  menuItemConfig: {
    icon: () => PageIconWithTooltip(`${DISHES_SETTINGS_PAGE_LOCALE_PATH}.name`, DishesImageSvg),
    label: `${DISHES_SETTINGS_PAGE_LOCALE_PATH}.label`,
    group: NO_NAME_ADMIN_MENU_GROUP
  }
};