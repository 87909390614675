import { useActiveUser, useDateQueryParam } from '@intelliarts/ia-react-template';

import { ActiveUserIdBaseContainerProps } from '../types';
import { StringDateRangeProps } from '../components/common/models/DateRangeProps';
import { getWeekBounds, toStringDateRange } from './date.utils';

export const useActiveUserProps = (): ActiveUserIdBaseContainerProps => {
  const { id: activeUserId } = useActiveUser();
  return {
    activeUserId
  };
};

export const useStringDateRangeProps = (): StringDateRangeProps => {
  const [ activeDate ] = useDateQueryParam();
  const weekBounds = getWeekBounds(activeDate);

  return toStringDateRange(weekBounds);
};