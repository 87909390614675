import { createContainer, createHook, createStore } from 'react-sweet-state';

type OrderIsModifiedState = {
  orderIsModified: boolean
}

const initialState: OrderIsModifiedState = {
  orderIsModified: false
};

const OrderIsModifiedContainer = createContainer({ displayName: 'order-is-modified-container' });

export const OrderIsModifiedStore = createStore({
  initialState,
  actions: {
    setOrderIsModified:
      (isModified: boolean) =>
        ({ setState }) => {
          setState({ orderIsModified: isModified });
        }
  },
  containedBy: OrderIsModifiedContainer,
});

export const useOrderIsModified = createHook(OrderIsModifiedStore);