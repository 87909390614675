export default {
  categoryFormDialog: {
    title: 'Ви створюєте Категорію!',
    editTitle: 'Ви редагуєте Категорію!',
    scope: {
      label: 'Доступна для'
    },
    id: {
      label: 'Ідентифікатор'
    },
    position: {
      label: 'Порядок відображення',
      helperText: 'Дозволено тільки числа!'
    },
    icon: {
      label: 'Іконка',
      helperText: 'Виберіть іконку категорії'
    },
    name: {
      label: 'Назва категорії',
      validateIsDisplayNameUnique:
        {
          message: 'Назва категорії є обов`язковою і має бути унікальною'
        }
    },
  },
  categoriesList: {
    columns: {
      position: {
        headerName: 'Порядок відображення'
      },
      name: {
        headerName: 'Назва'
      },
      icon: {
        headerName: 'Іконка',
      },
      isArchived: {
        headerName: 'Архівна',
      },
      editCategory: {
        headerName: 'Редагувати категорію',
        editLinkButton: {
          tooltipText: 'Редагувати'
        }
      },
    },
    toolbar: {
      displayArchivedCategoriesToggle: {
        label: 'Показати у т.ч. неактивні категорії',
      },
    }
  }
};
