import React from 'react';

import { DishModelFields } from '../../models/Dish';
import { Checkboxes } from 'mui-rff';

import { DISHES_FORM_DIALOG_PATH } from '../../config/dishesFormDialogConfig';
import { useTranslation } from 'react-i18next';

export const EverydayField = () => {
  const { t } = useTranslation();

  return <Checkboxes
    name={DishModelFields.IS_EVERYDAY}
    formControlProps={{
      margin: 'dense',
      fullWidth: true,
    }}
    data={{
      label: t(`${DISHES_FORM_DIALOG_PATH}.isEveryday.label`),
      value: true,
    }} />;
};