import React from 'react';

import { useCategories } from '../../../Categories/store/categoriesSelectors';
import { DishModelFields } from '../../models/Dish';
import { Select, SelectData, validateIsPresent } from '@intelliarts/ia-react-template';
import { DISHES_FORM_DIALOG_PATH } from '../../config/dishesFormDialogConfig';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import { useSelectedCategoryId } from '../../store/selectedCategoryIdStore';
import { Category } from '../../../Categories/models/Category';
import { Box } from '@mui/material';

export const DishesCategoryField = () => {
  const categories = useCategories();
  const [ , { setSelectedCategoryId } ] = useSelectedCategoryId();
  const { t } = useTranslation();

  const selectedCategory: Category = useField(DishModelFields.CATEGORY).input.value;
  setSelectedCategoryId(selectedCategory.id);

  const options: SelectData[] = categories.map(category => ({ label: category.name, value: category }));

  return (
    <>
      <Box sx={{ mt: 2 }} />
      <Select
        multiple={false}
        required={true}
        data={options}
        label={t(`${DISHES_FORM_DIALOG_PATH}.category.label`)}
        name={DishModelFields.CATEGORY}
        validator={validateIsPresent}
        variant="outlined"
        value={selectedCategory ? JSON.stringify(selectedCategory) : ''}
      />
    </>
  );
};