import { AppConfig } from '@intelliarts/ia-react-template';

import routesConfig from './routesConfig';
import authConfig from './authConfig';
import { locales } from '../locales';

const config: AppConfig = {
  appName: 'Luncher',
  defaultRedirectUrl: '/not-found-page',
  rootRedirectUrl: '/order',
  logoRedirectUrl: '/order',
  routes: routesConfig,
  auth: authConfig,
  withReduxLogger: false,
  locales: locales,
};

export default config;
