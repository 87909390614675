import React, { useEffect, useState } from 'react';
import { Autocomplete } from 'mui-rff';
import { useCategories } from '../../../Categories/store/categoriesSelectors';
import { DishModelFields } from '../../models/Dish';
import { DISHES_FORM_DIALOG_PATH } from '../../config/dishesFormDialogConfig';
import { useTranslation } from 'react-i18next';
import { useSelectedCategoryId } from '../../store/selectedCategoryIdStore';
import { useSelectedRowActions } from '../../store/selectedRowStore';
import { CategoryView } from '../../../Categories/models/CategoryView';
import { getDefaultBlockedCategories } from '../dishesListUtils';
import { useField } from 'react-final-form';

export const BlockedCategoryField = () => {
  const categories = useCategories();
  const [ { selectedCategoryId } ] = useSelectedCategoryId();
  const { t } = useTranslation();
  const { getSelectedRow, setSelectedRow } = useSelectedRowActions();
  const [ blockedCategories, setBlockedCategories ] = useState<any[]>([]);
  const [ filteredCategories, setFilteredCategories ] = useState<CategoryView[]>(categories);

  useEffect(() => {
    setFilteredCategories(categories.filter((c) => c.id !== selectedCategoryId));
    if (selectedCategoryId) {
      const selectedCategory = categories.find(category => category.id === selectedCategoryId);
      const selectedRow = getSelectedRow();
      setBlockedCategories(getDefaultBlockedCategories(selectedCategory, selectedRow, categories));
    }
  }, [ selectedCategoryId, categories ]);

  useField(DishModelFields.BLOCKED_CATEGORIES, { initialValue: blockedCategories });

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      options={filteredCategories}
      value={blockedCategories}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === 'object' && typeof value === 'object') {
          return option.name === value.name;
        }
        return option === value;
      }}
      sx={{ marginTop: '20px' }}
      getOptionLabel={(option) => {
        return typeof option === 'object' ? option.name : option;
      }}
      onChange={(e, newValue) => setBlockedCategories(newValue)}
      label={t(`${DISHES_FORM_DIALOG_PATH}.blockedCategories.label`)}
      name={DishModelFields.BLOCKED_CATEGORIES}
    />
  );
};
