import { DaoModel } from '@intelliarts/ia-react-template';

import { CategoryStore } from '../store/categoriesStore';
import { useSortedCategoryViews } from '../store/categoriesSelectors';
import { CategoryView } from '../models/CategoryView';

export class CategoryDao extends DaoModel<CategoryView> {
  constructor() {
    super({
      modelName: 'category',
      Store: CategoryStore,
    });
  }

  findByCode(code: string): CategoryView | null {
    const categoryViews = useSortedCategoryViews();
    return categoryViews.find(cw => cw.id === +code) || null;
  }

  getAll(): CategoryView[] {
    return useSortedCategoryViews();
  }
}

export const categoryDao = new CategoryDao();
