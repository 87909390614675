import { LocalesType } from '@intelliarts/ia-react-template';

import appLocalizationUK from './uk/localizationUK';
import appLocalizationEN from './en/localizationEN';

export const locales: LocalesType = {
  uk: {
    translation: appLocalizationUK,
  },
  en: {
    translation: appLocalizationEN,
  }
};
