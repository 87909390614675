import { Category } from './Category';

export type CategoryView = Category & {
  id: number;
  name: string;
  position: number;
  icon: string;
  isArchived: boolean;
}

export enum CategoryViewFields {
  ID = 'id',
  NAME = 'name',
  POSITION = 'position',
  ICON = 'icon',
  IS_ARCHIVED = 'isArchived',
  EDIT_CATEGORY = 'editCategory',
}
