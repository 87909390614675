import React from 'react';

import { useField } from 'react-final-form';
import { TextField } from '@intelliarts/ia-react-template';

import { CategoryModelFields } from '../../models/Category';
import { CATEGORIES_BASE_LOCALE_PATH } from '../../locales';
import { validateIsPresentIsIntegerAndIsPositive } from '../../../../utils/validators/number.validation';
import { useNextAvailableSequence } from '../../store/categoriesSelectors';

const LOCALE_PATH = `${CATEGORIES_BASE_LOCALE_PATH}.categoryFormDialog.position`;

export const PositionField = () => {
  const nextAvailableSequence = useNextAvailableSequence();
  const currentFieldValue = useField(CategoryModelFields.POSITION).input.value;
  const initValue = currentFieldValue !== '' ? currentFieldValue : nextAvailableSequence;
  const sequenceField = useField(CategoryModelFields.POSITION, { initialValue: initValue });

  return <TextField name={CategoryModelFields.POSITION}
                    label={`${LOCALE_PATH}.label`}
                    helperText={`${LOCALE_PATH}.helperText`}
                    type="number"
                    validator={validateIsPresentIsIntegerAndIsPositive}
                    value={sequenceField.input.value}
  />;
};
