import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

import { DishView } from '../models/DishView';
import { NO_CONTENT_STRING } from '@intelliarts/react-components';
import { CategoryView } from '../../Categories/models/CategoryView';

export const filterArchivedDishes = (food: DishView[]) => food.filter(dish => !dish.isArchived);

export const useRowClassName = ({ row }: GridRowClassNameParams) => {
  const { isArchived } = row;
  if (isArchived) {
    return 'dish-archived';
  } else {
    return NO_CONTENT_STRING;
  }
};

export const getDefaultBlockedCategories = (selectedCategory: CategoryView | undefined, selectedRow: DishView | null, categories: CategoryView[]) => {
  if (!selectedRow && selectedCategory) {
    if (selectedCategory.id === 2) {
      const garnish = categories.find(c => c.id === 3);
      const meat = categories.find(c => c.id === 6);
      return [ garnish, meat ];
    } else if (selectedCategory.id === 3 || selectedCategory.id === 6) {
      const main = categories.find(c => c.id === 2);
      return [ main ];
    }
  } else if (selectedRow && selectedRow.blockedCategories !== null && selectedRow.blockedCategories.length > 0) {
    return selectedRow.blockedCategories;
  }
  return [];
};
