import { createDataHook } from '@intelliarts/ia-react-template';
import { CategoryStore } from './categoriesStore';
import { CategoryView } from '../models/CategoryView';
import { MenuItemType } from '../../Menu/models/MenuItemType';

const sortBySequence = (a: CategoryView, b: CategoryView,) => {
  return a.position > b.position ? 1 : -1;
};

const getSortedCategoryViews = (categories: CategoryView[]) =>
  categories
    .sort(sortBySequence);
const getSortedAvailableCategoryViews = (categories: CategoryView[]) =>
  categories
    .filter(c => !c.isArchived)
    .sort(sortBySequence);

export const useNextAvailableSequence = createDataHook(
  CategoryStore,
  categories => {
    const STEP_BETWEEN_SEQUENCES = 1;
    const sequences = categories.map(cat => cat.position);
    const currentMaxSequence = Math.max(...sequences);
    return currentMaxSequence + STEP_BETWEEN_SEQUENCES;
  }
);

export const useCategories = createDataHook(
  CategoryStore,
  getSortedAvailableCategoryViews);

export const useSortedCategoryViews = createDataHook(CategoryStore, getSortedCategoryViews);

export const useIsCategoryItemSelected = (categoryId: number, orderItems: MenuItemType[]) => {
  return orderItems
    .flatMap(dailyOrder => dailyOrder.food.category)
    .filter(category => category.id === categoryId)
    .length > 0;
};
