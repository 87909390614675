export default {
  orderWeekPanelMessages: {
    noWeekOrderAvailable: '',
    noDailyOrderAvailable: '',
  },
  orderSidebarLabels: {
    weekPrice: 'Period total: ₴',
    orderButton: 'Order',
    reOrderButton: 'Reorder',
    ordered: 'Ordered',
    openOrderPanel: 'Open order panel',
    closeOrderPanel: 'Close order panel',
    holiday: 'Holiday',
    nothingSelected: 'Nothing selected',
  },
  menuWeekPanelMessages: {
    orderHasDraftMenu: 'Menu is still not PUBLISHED. Unable to order DRAFT menu.'
  },
};